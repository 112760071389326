import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildConfigurationFieldUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';
import { ConfigurationFieldsResponse } from '../types';

export const deleteConfigurationField = (id: string): Observable<ConfigurationFieldsResponse> => {
    const obs = sendDeleteRequestWithXSRFToken(buildConfigurationFieldUrl(id));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationFieldsResponse>) => {
            const res = convertToCamel<ConfigurationFieldsResponse>(response);
            return observableOf(res);
        }),
    );
};
